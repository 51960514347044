import {
  Form,
  Input,
  InputNumber,
  Radio,
  TreeSelect,
  Select,
  Button,
  Modal,
  Collapse,
  Row,
  Col,
  Spin,
  notification,
  message, Steps
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { debounce, get } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { ARTWORK } from "../../../types";
import ClipartCategorySelector from "../../cliparts/ClipartCategorySelector";
import FileField from "../../upload/FileField";
import Cliparts from "./Cliparts";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {AiFillCamera} from 'react-icons/ai'
// import ImagesField from "./ImagesField";
import { ChromePicker} from 'react-color';
import { AiFillSetting } from 'react-icons/ai';
// import GoogleMapReact from 'google-map-react';
import {
  ASSET_URL,
  AWS_API,
  URL_API_FILL_IMAGES_SHAPE,
  URL_API_RENDER_SPIRAL_CUSTOM_LYRICS,
  URL_API_RENDER_SPIRAL_LYRICS
} from "../../../config";
import {generate} from "short-uuid"
import axios from "axios";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api';
import ColorField from "../Utilities/ColorField";
import UploadFontLyric from "../Utilities/UploadFontLyric";
import SongLyric from "../Utilities/SongLyric";
import {uniqueID} from "../Utilities/helper";
import FontSizeChooseLyric from "../Utilities/FontSizeChooseLyric";
import UploadSVGLyric from "../Utilities/UploadSVGLyric";

const { Option } = Select;
const { Panel } = Collapse;
const Container = styled.div`
  .ant-col {
    &.active {
      img {
        background: #f5f5f5;
        border: 1px solid #ddd;
        border-radius: 3px;
      }
    }
    .delete {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 15px;
      color: #ff7875;
    }
  }

  .layer-hide {
    display: none !important;
  }
  
`;

const ImageForm = ({ layer, workspace }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { artwork, selectedTemplate } = workspace;
  const layers =
    selectedTemplate === -1
      ? get(artwork, "sharedLayers", [])
      : get(artwork, `templates[${selectedTemplate}].layers`) || [];
  const update = (layer) => {
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: layer,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...layer,
      ...{
        personalized: {
          type: "images",
          label: layer.title,
          ...(layer.personalized || {}),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer]);
  const renderTreeNodesHidden = (l) => {
    return (
      <TreeSelect.TreeNode
        value={l.id}
        title={l.title || l.personalized?.label || l.title || l.id}
        key={l.id}
        disabled={
          l.id === layer.id ||
          l.type === "Group" ||
          (l.personalized && l.personalized.enable !== true) ||
          !l.personalized ||
          l.parent === layer.id ||
          l.type !== layer.type
        }
      >
        {Array.isArray(l.layers) &&
          l.layers.map((l) => renderTreeNodesHidden(l))}
      </TreeSelect.TreeNode>
    );
  };

  const onValuesChange = (_, values) => {
    update({
      ...layer,
      ...values,
      personalized: { ...layer.personalized, ...values.personalized },
    });
  };


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
    },
    wrapperCol: {
      xs: { span: 0 },
      sm: { span: 0 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 0, offset: 0 },
      sm: { span: 0, offset: 0 },
    },
  };


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const rendetTypeMapId = () => {
    let type = "roadmap";
    if('typeMap' in layer){
      switch (layer.typeMap) {
        case 'roadmap':
          type = "roadmap";
          break;
        case 'hybrid':
          type = "hybrid";
          break;
        case 'terrain':
          type = "terrain";
          break;
        case 'satellite':
          type = 'satellite';
          break;
        default:
          type = 'roadmap';
          break;
      }
    }
    return type;
  }

  const onLoadMap = (map) => {
    map.mapTypeId = rendetTypeMapId();
  }


  const [isModalBackyellow, setIsModalBackyellow] = useState(false)

  const [isTypeMap, setIsTypeMap] = useState(rendetTypeMapId());

  const [isModalScaleStarsLighter, setIsModalScaleStarsLighter] = useState(false)
  const [isModalScaleStarsBolder, setIsModalScaleStarsBolder] = useState(false)
  const [loadingCrop, setLoadingCrop] = useState(false);

    const [color_starcolor, setcolor_starcolor] = useState(layer.imageStarMap ? layer.starcolor : "#ffffff");
    const [display_color_starcolor, setdisplay_color_starcolor] = useState(false);


    const popover = {
        position: 'absolute',
        zIndex: '2',
        top: "30px"
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const colorPikerPreview = {
        height: "30px",
        transition: "all 500ms ease 0s",
        border: "1px solid #000",
    }

  var OnMapLyric
  if(layer.imageLyric || layer.imageMap || layer.imageStarMap){
    OnMapLyric = true
  }else {
    OnMapLyric = false
  }

  var OnClipPartImage
  if(layer.imageLyric || layer.imageMap || layer.imageStarMap || layer.imageShape || layer.imageCutFace || layer.imageRemoveBackground){
    OnClipPartImage = true
  }else {
    OnClipPartImage = false
  }

  var OnStereo_typeSkyStar
  if(layer.imageStarMap && layer.typeSkyStar == 'stereo'){
    OnStereo_typeSkyStar = true
  }else{
    OnStereo_typeSkyStar = false
  }

  var OnPolar_typeSkyStar
  if(layer.imageStarMap && layer.typeSkyStar == 'polar'){
    OnPolar_typeSkyStar = true
  }else {
    OnPolar_typeSkyStar = false
  }


  var OnTransparentBackground
  if(layer.imageStarMap && layer.transparentbackground == false){
    OnTransparentBackground = true
  }else {
    OnTransparentBackground = false
  }
  const [StyleMap, setStyleMap] = useState(layer.jsonDataMap ? layer.jsonDataMap : []);
  const [visibleSetting, setVisibleSetting] = useState(layer.settingDefault ? layer.settingDefault : false);

  const OnOpenSetting = () => {
    setVisibleSetting(true);
  }

  const OnClickSaveSetting = () => {
    layer.jsonDataMap = StyleMap;
    setVisibleSetting(false);
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        settingDefault: false
      },
    });
  }

  useEffect(()=> {
    layer.jsonDataMap = StyleMap;
  }, [StyleMap])

  const googleStaticMapsStylerQuery = (styles) => {
    let query = "";
    for (const style of styles) {
      const component = [];
      if (style.featureType) component.push("feature:" + style.featureType);
      if (style.elementType) component.push("element:" + style.elementType);
      for (const rule of /** @type {Array<{ [key: string]: unknown }>} */ (
          style.stylers
      )) {
        const [name] = Object.keys(rule);
        const value = String(rule[name]).replace("#", "0x");
        component.push(`${name}:${value}`);
      }
      query += "&style=" + encodeURIComponent(component.join("|"));
    }
    return query;
  };

  const JsonToMap = (json) => {
    const lat = 29.95106579999999;
    const lng = -90.0715323;
    const zoom = 12;
    const type_id = isTypeMap;
    const width = 700;
    const height = 700;
    const ApiKeyMap = "AIzaSyD2Kty-jKboVyvy-mnWMDd5JUzC4qE5pqc"
    const v_scale = "2";

    const stringBefore = `https://maps.googleapis.com/maps/api/staticmap?key=${ApiKeyMap}&center=${lat},${lng}&zoom=${zoom}&format=png&maptype=${type_id}&size=${width}x${height}&scale=${v_scale}`;
    const StringJson = googleStaticMapsStylerQuery(json);
    const link = stringBefore + StringJson

    return link;
  };

  // map google
  const [centerMap, setCenterMap] = useState(layer.centerMap ? layer.centerMap : {
    lat: -3.745,
    lng: -38.523
  });

  const [centerMarker, setCenterMarker] = useState(layer.centerMarker ? layer.centerMarker : {
    lat: -3.745,
    lng: -38.523
  });
  const [zoomMap, setZoomMap] = useState(layer.zoomMap ? layer.zoomMap : 10);

  useEffect(() => {
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        centerMap: centerMap
      },
    });
  }, [centerMap]);

  useEffect(() => {
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        centerMarker: centerMarker
      },
    });
  }, [centerMarker]);

  useEffect(() => {
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        zoomMap: zoomMap
      },
    });
  }, [zoomMap]);


  const  onZoomChangedMap = (e) => {
    const AutoMap = MapGoogle.current;
    if(typeof AutoMap != "undefined"){
      const zoom = MapGoogle.current.state.map.zoom;
      setZoomMap(zoom);
    }
  }

  const renderCoordinatesMap = (lat, lng) => {
    let iconLat = Number(lat) < 0 ? 'S' : 'N';
    let iconLng = Number(lng) < 0 ? 'W' : 'E';
    return Math.abs(lat).toFixed(4) + '° ' + iconLat + ', ' + Math.abs(lng).toFixed(4)  + '° ' + iconLng;
  }

  const handleCopyCoordinatesMap = () => {
    const text = renderCoordinatesMap(centerMap.lat, centerMap.lng);
    navigator.clipboard.writeText(text);
    notification.success({
      description: 'Successfully copied: " '+ text +' " ',
    });
  }

  const onDragEndMap = () => {
    const AutoMap = MapGoogle.current.state.map;
    const Center = AutoMap.center;
    const center = {
      lat: Center.lat(),
      lng: Center.lng()
    }
    setCenterMap(center)
  }


  const [iconMarker, setIconMarker] = useState(layer.iconMarker && layer.iconMarker.length > 0 ? ASSET_URL + layer.iconMarker[0].file.key : null);
  const [visibleModalCamera, setVisibleModalCamera] = useState(false);
  const [widthMapCrop, setWidthMapCrop] = useState(1280);
  const [heightMapCrop, setHeightMapCrop] = useState(1280);
  const [visibleModalCrop, setVisibleModalCrop] = useState(false);
  const [srcImgCrop, setSrcImgCrop] = useState(null);
  const [optionCrop, setOptionCrop] = useState('crop_to_shape');
  const [imgShape, setImgShape] = useState(layer.imageMap && layer.shapes && layer.shapes.length > 0 ? ASSET_URL + layer.shapes[0].file.key : null)

  const handleChangeOptionCrop = (val) => {
    setOptionCrop(val);
    CheckSelectCropOption();
  }

  const SaveChangeSetting = (index1, index2, key, value) => {
    const Styles = StyleMap;
    Styles[index1].stylers[index2][key] = value;
    setStyleMap(JSON.parse(JSON.stringify(Styles)))
  }

  const OnChangeWidthMapCrop = (val) => {
    setWidthMapCrop(val);
  }

  const OnChangeHeightMapCrop = (val) => {
    setHeightMapCrop(val);
  }

  const autoMap = useRef();
  const inputFileMarker = useRef();
  const MapGoogle = useRef();
  const cropperRef = useRef();

  const OnchangePlacesAutocomplete = () => {
    let AutoMap = autoMap.current;
    const Geometry = AutoMap.state.autocomplete.getPlace().geometry.location;
    const center = {
      lat: Geometry.lat(),
      lng: Geometry.lng()
    }
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        centerMap: center,
        centerMarker: center
      },
    });
    setCenterMarker(center);
    setCenterMap(center);
  }

  const containerStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute'
  };

  const [typeSettingValue, setTypeSettingValue] = useState(layer.imageMap && layer.typeSettingValue ? layer.typeSettingValue : "default")

  const OnClickChooseMarker = () => {
    const DivInput = inputFileMarker.current;
    const input = document.querySelector('#inputFileMarker input');
    input.click();
  }

  const OnSetFile = (file) => {
    const url = ASSET_URL + file.key;
    setIconMarker(url);

    layer.iconMarker = [{file: file}]
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        iconMarker: [{file: file}]
      },
    });

    layer.makerstatus = true;
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        makerstatus: true
      },
    });
  }

  const OnRemoveMarker = () => {
    setIconMarker(null);
    layer.makerstatus = false;
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        makerstatus: false
      },
    });
  }

  const RederImageMapDefauld = (lat,lng,zoom,width,height,marker,json, typeMapId) => {
    const v_scale = "2";
    const stringBefore = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyD2Kty-jKboVyvy-mnWMDd5JUzC4qE5pqc&center=${lat},${lng}&zoom=${zoom}&format=png${marker}&maptype=${typeMapId}&size=${width}x${height}&scale=${v_scale}`;
    const StringJson = googleStaticMapsStylerQuery(json);
    const link = stringBefore + StringJson;
    return link;
  }

  const OnCLickGetMapCamera = () => {
    const AutoMap = MapGoogle.current.state.map;
    const Center = AutoMap.center;
    const Zoom = AutoMap.zoom;
    setZoomMap(Zoom)
    const center = {
      lat: Center.lat(),
      lng: Center.lng()
    }
    setCenterMap(center);
    setVisibleModalCamera(true);
  }

  const upload = async (base64) => {
    var file = await fetch(base64)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], 'tmp.png', { type: 'image/png' }))
    var signedUrl = await getSignedUrl(file.type)
    await axios
        .put(signedUrl.url, file, {
          headers: {
            'Content-Type': file.type
          }
        })
        .catch(err => console.error(err))
    return { key: signedUrl.key }
  };

  const getSignedUrl = async (mime) => {
    var headers = new Headers()
    headers.append('Content-Type', 'application/json')
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    var signedUrl = null
    await fetch(
        `${AWS_API}/signedUrl?type=${mime}`,
        requestOptions
    )
        .then(response => response.json())
        .then(res => (signedUrl = res))
        .catch(err => console.error(err))
    return signedUrl
  }
  const Download = (fileUrl, fileName) => {
    var a = document.createElement("a");
    a.href = fileUrl;
    a.setAttribute("download", fileName);
    a.setAttribute('target', "_blank")
    a.click();
  }

  const OnCropImagesMap = async () => {
    setLoadingCrop(true)
    const cropper = cropperRef.current.cropper;
    const base64 = cropper.getCroppedCanvas().toDataURL();
    const Upload = await upload(base64);
    const id = layer.values[0].file.id;

    if(optionCrop == 'default'){
      layer.values[0].file = {
        'id': id,
        'key': Upload.key
      }
      dispatch({
        type: ARTWORK.SET_LAYER,
        payload: {
          ...layer,
          values: [{ file: {
              'id': id,
              'key': Upload.key
            } }],
        },
      });
    }else {
      if ('shapes' in layer && layer.shapes.length > 0){
        const shape = ASSET_URL + layer.shapes[0].file.key;
        const ImageShapeRawResponse = await fetch(URL_API_FILL_IMAGES_SHAPE, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            urlShape : shape,
            urlImages: [ASSET_URL + Upload.key]
          })
        });
        const contentCanvas = await ImageShapeRawResponse.json();
        layer.values[0].file = {
          'id': id,
          'key': contentCanvas.url.key
        }
        dispatch({
          type: ARTWORK.SET_LAYER,
          payload: {
            ...layer,
            values: [{ file: {
                'id': id,
                'key': contentCanvas.url.key
              } }],
          },
        });
      }
    }

    if(layer.settingDefault){
      dispatch({
        type: ARTWORK.SET_LAYER,
        payload: {
          ...layer,
          settingDefault: false
        },
      });
    }

    setVisibleModalCrop(false);
    setVisibleModalCamera(false);
    setVisibleSetting(false)
    setLoadingCrop(false);
  }

  const handleOkRenderMap = async () => {
    let StringMarker
    if(iconMarker != null){
      StringMarker = `&markers=icon:${iconMarker}|${centerMarker.lat},${centerMarker.lng}`
    }else {
      StringMarker = '';
    }
    const links = RederImageMapDefauld(centerMap.lat, centerMap.lng, zoomMap, widthMapCrop, heightMapCrop,StringMarker, StyleMap, isTypeMap);
    setSrcImgCrop(links);
    setVisibleModalCrop(true);
  }
  const handleCancelRenderMap = () => {
    setVisibleModalCamera(false)
  }

  const onCropImageMap = () => {
    const cropper = cropperRef.current.cropper;
  }

  const OnDragEndMarker = (val) => {
    const latLng = val.latLng;
    const center = {
      lat: latLng.lat(),
      lng: latLng.lng()
    }
    setCenterMarker(center);
  }

  const CheckSelectCropOption = () => {
    const DomShape = document.querySelector('.hdev-shape-image-dom');
    if(optionCrop === "default"){
      DomShape.classList.remove('hide')
      DomShape.classList.add('show');
    }else {
      DomShape.classList.remove('show');
      DomShape.classList.add("hide");
    }
  }

  const ChangeCropMap = (e) => {
    const AbnDomCrop = document.querySelector('.cropper-crop-box')

    AbnDomCrop.style.Position = 'relative';

    var Abndiv = document.createElement("div");
    Abndiv.className = "hdev-shape-image-dom";
    Abndiv.classList.add('show');
    Abndiv.style.Position = 'absolute';

    var Abnimg = document.createElement('img')
    Abnimg.src = imgShape;

    Abndiv.append(Abnimg)

    AbnDomCrop.append(Abndiv);
  }

  const [listTypesMap, setListTypesMap] = useState(window.LibraryTypeMapAbn.TYPES());



  // start Setting lyric
  const [visibleModelChooseTypeLyric, setVisibleModelChooseTypeLyric] = useState(false);
  const [typeSpiralLyric, setTypeSpiralLyric] = useState(layer.typeSpiral ? layer.typeSpiral : "1");
  const [onNextChooseLyric, setOnNextChooseLyric] = useState(2);
  const [songLyric, setSongLyric] = useState(layer.lyricTextDefault ? layer.lyricTextDefault : "");
  const [colorTextLyric, setColorTextLyric] = useState(layer.colorTextSpiral ? layer.colorTextSpiral : "#ffffff");
  const [fontTextLyric, setFontTextLyric] = useState(layer.fontJsonSpiral ? layer.fontJsonSpiral : "");
  const [loadingApply, setLoadingApply] = useState(false);
  const [fontSizeLyric, setFontSizeLyric] = useState(layer.defaultFontSizeSpiralLyric ? layer.defaultFontSizeSpiralLyric : 16);
  const [svgUrlLyric, setSvgUrlLyric] = useState(layer.svgUrlSpiralLyric ? layer.svgUrlSpiralLyric : {name: null, value: ""});
  const [minCharacters, setMinCharacters] = useState(layer.minCharacters ? layer.minCharacters : 0);
  const [maxCharacters, setMaxCharacters] = useState(layer.maxCharacters ? layer.maxCharacters : 1200);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleChangeMinCharacters = (value) => {
    setMinCharacters(value);
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        minCharacters: value
      },
    });
  }

  const handleOnChangeLoadingUpload = (v) => {
    setLoadingUpload(v)
  }

  const handleChangeMaxCharacters = (value) => {
    setMaxCharacters(value);
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        maxCharacters: value
      },
    });
  }

  const handleShowModleSetting = () => {
    setVisibleModelChooseTypeLyric(true);
  }

  const handleCancelModelChooseTypeLyric = () => {
    setVisibleModelChooseTypeLyric(false);
  }

  const handleNextSpiralChooseType = () => {
    setOnNextChooseLyric(2);
  }

  const handlePrev2ChooseType = () => {
    setOnNextChooseLyric(1)
  }

  const handChangeTypeSpiral = (v) => {
    setTypeSpiralLyric(v.target.value)
  }

  const handChangeFontSizeLyric = (value) => {
    setFontSizeLyric(value);
  }

  const handleChangeUrlSvgLyric = (value) => {
    setSvgUrlLyric(value);
  }

  const OnChangeSongLyric = v => {
    setSongLyric(v);
  }
  const handleOnchangeColorLyricText = (v) => {
    setColorTextLyric(v);
  }

  const handleOnSelectFontFamilyLyric = async (v) => {
    setFontTextLyric(v);
  }

  const handleApplySpiralChooseType = async () => {
    setLoadingApply(true);
    let body;
    let API_URL;
    if(typeSpiralLyric == "5"){
      body = {
        "svgUrl": svgUrlLyric.value,
        "lyrics": songLyric,
        "defaultFontSize": fontSizeLyric,
        "fontFamily": fontTextLyric,
        "color": colorTextLyric
      }
      API_URL = URL_API_RENDER_SPIRAL_CUSTOM_LYRICS;
    }else {
      body = {
        type: typeSpiralLyric,
        lyrics: songLyric,
        fontFamily: fontTextLyric,
        color: colorTextLyric
      };
      API_URL = URL_API_RENDER_SPIRAL_LYRICS;
    }

    const renderLyric = await axios.post(API_URL, body);

    if(renderLyric.status == 200){
      const url_res = renderLyric.data.response_url;
      const valuesLayer = get(layer, 'values', []);
      valuesLayer[0].file.key = url_res;
      if(typeSpiralLyric == "5"){
        dispatch({
          type: ARTWORK.SET_LAYER,
          payload: {
            ...layer,
            values: valuesLayer,
            typeSpiral: typeSpiralLyric,
            fontJsonSpiral: fontTextLyric,
            colorTextSpiral: colorTextLyric,
            lyricTextDefault: songLyric,
            svgUrlSpiralLyric: svgUrlLyric,
            defaultFontSizeSpiralLyric: fontSizeLyric
          },
        });
      }else {
        dispatch({
          type: ARTWORK.SET_LAYER,
          payload: {
            ...layer,
            values: valuesLayer,
            typeSpiral: typeSpiralLyric,
            fontJsonSpiral: fontTextLyric,
            colorTextSpiral: colorTextLyric,
            lyricTextDefault: songLyric
          },
        });
      }
      setVisibleModelChooseTypeLyric(false);
      setOnNextChooseLyric(1);
    }else {
      message.error('An incident has occurred. Please try again!');
    }

    setLoadingApply(false);
  }

  const typesSpiral = [
    {
      label: "default",
      value: "1",
      src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/rKgLvk245SSHnrWR1EXTPd.png"
    },
    {
      label: "1500",
      value: "3",
      src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/7KkdJW3tqpvv81nkxTMX3K.png"
    },
    {
      label: "2000",
      value: "4",
      src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/sDvCpkZmsfrbGYbACx3sT7.png"
    },
    // {
    //   label: "Custom",
    //   value:"5",
    //   src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/iwLFF1113DMbHw2KQjw1sv.png"
    // },
  ];

  const { Step } = Steps;

  // end Setting lyric


  return (
    <Container>
      {!OnMapLyric ?
          <div
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
          >
            <span>Hidden layer: </span>
            <Checkbox
                checked={layer.hidden}
                onChange={(value) => {
                  dispatch({
                    type: ARTWORK.SET_LAYER,
                    payload: {
                      ...layer,
                      dependOnLayer: null,
                      personalized: {
                        ...layer.personalized,
                        enable: false,
                      },
                      hidden: value.target.checked,
                    },
                  });
                }}
            />
          </div> : null }
      {get(layer, "hidden") && (
        <div style={{ marginBottom: 10 }}>
          <span>Depend on layer : </span>
          <TreeSelect
            value={layer.dependOnLayer}
            style={{ width: "100%" }}
            treeDefaultExpandAll
            onChange={(v) => {
              dispatch({
                type: ARTWORK.SET_LAYER,
                payload: {
                  ...layer,
                  dependOnLayer: v,
                },
              });
            }}
          >
            {layers.map((l) => renderTreeNodesHidden(l))}
          </TreeSelect>
        </div>
      )}
      <Form
        form={form}
        onValuesChange={debounce(onValuesChange, 100)}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
      >
        {!get(layer, "personalized.enable") && (
          <Form.Item name={["values", 0, "file"]}>
            <FileField size={64} accept=".jpg, .png, .jpeg" loading={true} />
          </Form.Item>
        )}
        <Form.Item
          name={["personalized", "enable"]}
          valuePropName="checked"
          label="Allow personalized"
          labelCol={{ span: 21 }}
          wrapperCol={{ span: 3 }}
          labelAlign="left"
          style={{ textAlign: "right" }}
        >
          <Checkbox disabled={layer.hidden} />
        </Form.Item>
        {get(layer, "personalized.enable") && (
          <>
            <Form.Item
              label="Ordering"
              name={"ordering"}
              initialValue={layer.ordering ? layer.ordering : 0}
            >
              <InputNumber min={0} max={99} />
            </Form.Item>

            <Form.Item label="Label" name={["personalized", "label"]}>
              <Input />
            </Form.Item>
            {!OnClipPartImage ?
                <Form.Item
                label="Help text" name={["personalized", "help"]}>
              <Input size="small" placeholder="Field description..." />
            </Form.Item> : null }

            {layer.imageLyric && layer.imageSpiralLyric ?
                <div>
                  <Button style={{
                    height: "35px",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "600"
                  }} type="primary" onClick={handleShowModleSetting}>Setting</Button>

                  <Modal
                      title={[
                        <div style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }} key={5}>
                          <div style={{
                            width: "30%"
                          }}>Song lyric layer</div>
                          <Steps style={{
                            width: "60%"
                          }} size="small" current={onNextChooseLyric}>
                            <Step title="Choose style" />
                            <Step title="Spiral Type" />
                            <Step title="Configure" />
                          </Steps>
                        </div>
                      ]}
                      visible={visibleModelChooseTypeLyric}
                      className="modal-choose-type-lyric"
                      onCancel={handleCancelModelChooseTypeLyric}
                      width={750}
                      footer={[
                        <Button key="back" onClick={handleCancelModelChooseTypeLyric}>
                          Cancel
                        </Button>,
                        <div key={5}>
                          {onNextChooseLyric == 1 ?
                              <div key={6}>
                                <Button key="submit" type="primary" onClick={handleNextSpiralChooseType}>
                                  Next
                                </Button>
                              </div>
                              : null}
                          {onNextChooseLyric == 2 ?
                              <div key={7}>
                                <Button disabled={loadingUpload} key="prev2" type="primary" onClick={handlePrev2ChooseType}>
                                  Prev
                                </Button>
                                {typeSpiralLyric == 5 ?
                                    <Button loading={loadingApply} disabled={songLyric === "" || svgUrlLyric.value === "" || loadingUpload} key="submit" type="primary" onClick={handleApplySpiralChooseType}>
                                      Apply
                                    </Button>
                                    :
                                    <Button loading={loadingApply} disabled={songLyric === "" || loadingUpload} key="submit" type="primary" onClick={handleApplySpiralChooseType}>
                                      Apply
                                    </Button>
                                }
                              </div>
                              : null}

                        </div>,
                      ]}
                  >
                    <div>
                      {onNextChooseLyric == 1 ?
                          <div className="box-modal-choose-spiral-type-lyric">
                            <Radio.Group onChange={handChangeTypeSpiral} defaultValue={typeSpiralLyric} buttonStyle="solid">
                              {typesSpiral.map((item, index)=> (
                                  <Radio.Button key={index} className="box-modal-choose-type-lyric-item" value={item.value}>
                                    <img className="img-type-lyric-choose" src={item.src} />
                                    <div className="div-title-type-lyric">
                                      {item.label}
                                    </div>
                                  </Radio.Button>
                              ) )}
                            </Radio.Group>
                          </div>
                          :null}
                      {onNextChooseLyric == 2 ?
                          <div>
                            <div className="box-control-lyric-modal">
                              <div className="box-color-lyrix">
                                <ColorField value={colorTextLyric} onChange={handleOnchangeColorLyricText} />
                              </div>
                              {typeSpiralLyric == "5" ? <FontSizeChooseLyric value={fontSizeLyric} onChange={handChangeFontSizeLyric} /> : null}
                              <UploadFontLyric onChangeLoading={handleOnChangeLoadingUpload} value={fontTextLyric} onChange={handleOnSelectFontFamilyLyric} />
                              {typeSpiralLyric == "5" ? <UploadSVGLyric onChangeLoading={handleOnChangeLoadingUpload} value={svgUrlLyric} onChange={handleChangeUrlSvgLyric} /> : null}
                            </div>
                            <SongLyric min={minCharacters} max={maxCharacters} onChangeMinCharacters={handleChangeMinCharacters} onChangeMaxCharacters={handleChangeMaxCharacters}  value={songLyric} onChange={OnChangeSongLyric} />
                          </div>
                          :null}
                    </div>
                  </Modal>
                </div>
            :null}

            {layer.imageLyric && !layer.imageSpiralLyric ?
            <Form.Item label="Shape" name={["shapes", 0, "file"]}>
              <FileField
                  size={64}
                  loading="true"
                  accept=".jpg, .png, .jpeg"
                  onChange={(file) => {
                    if (layer.personalized && layer.personalized.enable) {
                      let newLayers = layer.parent
                          ? layers.search(layer.parent).layers
                          : layers;
                      let layerDepend = newLayers.filter(
                          (item) => item.dependOnLayer === layer.id
                      );
                      if (layerDepend.length > 0) {
                        for (
                            let index = 0;
                            index < layerDepend.length;
                            index++
                        ) {
                          const element = layerDepend[index];
                          dispatch({
                            type: ARTWORK.SET_LAYER,
                            payload: {
                              ...element,
                              shapes: [{file: file}]
                            },
                          });
                        }
                      }
                    }
                  }}
              />
            </Form.Item>
           : null }

            {layer.imageMap ?
                <>
                  <Button style={{
                    padding: "0px",
                    height: "35px",
                    width: "100%",
                    fontSize: "17px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px"
                  }
                  } onClick={OnOpenSetting} type="primary">
                    <AiFillSetting style={{
                      marginRight: "2px"
                    }}/> Settings
                  </Button>
                </>
                :null }

            {layer.imageMap ?
                <Form.Item label="Shape" name={["shapes", 0, "file"]}>
                  <FileField
                      size={64}
                      loading="true"
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                        if('key' in file){
                          setImgShape(ASSET_URL + file.key);
                          dispatch({
                            type: ARTWORK.SET_LAYER,
                            payload: {
                              ...layer,
                              shapes: [{file: file}]
                            },
                          });
                        }
                        if (layer.personalized && layer.personalized.enable) {
                          let newLayers = layer.parent
                              ? layers.search(layer.parent).layers
                              : layers;
                          let layerDepend = newLayers.filter(
                              (item) => item.dependOnLayer === layer.id
                          );
                          if (layerDepend.length > 0) {
                            for (
                                let index = 0;
                                index < layerDepend.length;
                                index++
                            ) {
                              const element = layerDepend[index];
                              dispatch({
                                type: ARTWORK.SET_LAYER,
                                payload: {
                                  ...element,
                                  shapes: [{file: file}]
                                },
                              });
                            }
                          }
                        }
                      }}
                  />
                </Form.Item>
                : null }

            <Modal
                title="Settings"
                centered
                visible={visibleSetting}
                onOk={OnClickSaveSetting}
                onCancel={() => setVisibleSetting(false)}
                width={1111}
                okText={"Save settings"}
                className={"modal_setting"}
            >
              <div className={"body_modal_setting"}>
                <div className={"body_modal_setting_item1"}>
                  <div className={"header_settings_modal"}>
                    <Radio.Group defaultValue={typeSettingValue} onChange={(value)=> {
                      layer.typeSettingValue = value.target.value;
                      setTypeSettingValue(value.target.value);
                    }}>
                      <Radio value={"default"}>Default</Radio>
                      <Radio value={"codeJson"}>Code Json</Radio>
                    </Radio.Group>
                    <Form.Item className={"select_type_map_layer"} name={"typeMap"}>
                      <Select
                          defaultValue="roadmap"
                          size='larg'
                          onChange={async (value)=>{
                            const TypeMapFind = listTypesMap.find(element => element.key == value);
                            setStyleMap(TypeMapFind.data);
                            setIsTypeMap(TypeMapFind.typeMapId);
                            dispatch({
                              type: ARTWORK.SET_LAYER,
                              payload: {
                                ...layer,
                                typeMapId: value,
                              },
                            });
                          }
                          }
                      >
                        {listTypesMap.map((item, index) => (
                            <Option key={index} value={item.key}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                  </div>

                  {typeSettingValue == 'default' ?
                      <ol className="setting_body_ol">
                        {StyleMap.map((item, index1) => (
                            <li key={index1}>
                              <label className="title_style_setting">{item.elementType}.{item.featureType}</label>
                              <div>
                                {item.stylers.map((styles, index2) => (
                                    <ul key={index2} className="list_style_setting">
                                      {Object.entries(styles).map(([key, value], index3) => (
                                          <li className={"li_group_item_style"} key={index3}>
                                            <label>{key}: </label>

                                            {key == 'visibility' ?
                                                <select className="select-visibility" defaultValue={value} onChange={(valuev) => {
                                                  SaveChangeSetting(index1, index2, key, valuev.target.value);
                                                }}>
                                                  <option value="on">On</option>
                                                  <option value="simplified">Simplified</option>
                                                  <option value="off">Off</option>
                                                </select>
                                                :null}
                                            {key == 'color' || key == 'hue' ?
                                                <>
                                                  <Input className="colorInputSetting" defaultValue={value} type={"color"} onBlur={(valuev)=> {
                                                    SaveChangeSetting(index1, index2, key, valuev.target.value);
                                                  }}/>
                                                </>
                                                :null}
                                            {(key != 'color' || key != 'hue' || key != 'visibility') ?
                                                <label  className={"Type-"+key}>{value}</label>
                                                : null}
                                          </li>
                                      ))}
                                    </ul>
                                ))}
                              </div>
                            </li>
                        ))}
                      </ol>
                  : null}
                  {typeSettingValue == "codeJson" ?
                      <div className={"setting_body_ol"}>
                        <textarea
                            className={"codeJson-Textarea"}
                            defaultValue={JSON.stringify(StyleMap)}
                            onChange={(value)=> {
                              setStyleMap(JSON.parse(value.target.value))
                            } }
                        />
                      </div>
                  :null}
                </div>

                <div className={"body_modal_setting_item2"}>
                      <div>
                        <LoadScript
                            googleMapsApiKey="AIzaSyD2Kty-jKboVyvy-mnWMDd5JUzC4qE5pqc"
                            libraries={["places"]}
                        >
                          <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={centerMap}
                              ref={MapGoogle}
                              mapTypeId={isTypeMap}
                              onDragEnd={onDragEndMap}
                              onZoomChanged={onZoomChangedMap}
                              options={{
                                zoomControl: false,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUI: false,
                                styles: StyleMap
                              }}
                              zoom={zoomMap}
                              onLoad={onLoadMap}
                          >
                            {iconMarker != null ?
                                <Marker
                                    position={centerMarker}
                                    draggable={true}
                                    onDragEnd={OnDragEndMarker}
                                    icon={{
                                      url: iconMarker,
                                      size: {width: 64, height: 64, f: 'px', b: 'px',},
                                      scaledSize: {width: 64, height: 64, f: 'px', b: 'px',},
                                    }}
                                />
                            :null}

                            <div className={"controller_map_header"}>
                              <Autocomplete
                                  ref={autoMap}
                                  onPlaceChanged={OnchangePlacesAutocomplete}
                              >
                                <input
                                    type="text"
                                    placeholder="Customized your placeholder"
                                    className={"input_search_google_map"}
                                />
                              </Autocomplete>

                              <div className={"market_input_box"}>
                                <div id={"inputFileMarker"} ref={inputFileMarker} className={"inputFileMarker"}>
                                  <FileField
                                      size={64}
                                      accept=".jpg, .png, .jpeg"
                                      onChange={OnSetFile}
                                  />
                                </div>

                                <button style={{
                                  boxShadow: `0px 0px 2px ${layer.makerstatus ? 'green' : '#515151'}`
                                }} className={"BtnChooseMarker"} onClick={OnClickChooseMarker}>Choose Marker</button>
                                {iconMarker != null ?
                                    <div className={"btnRemoveMarker"} onClick={OnRemoveMarker}>&#10005;</div>
                                : null}
                              </div>
                              <div onClick={handleCopyCoordinatesMap} className={"coordinates-header-view"}>
                                { 'makerstatus' in layer && layer.makerstatus ? renderCoordinatesMap(centerMarker.lat, centerMarker.lng) : renderCoordinatesMap(centerMap.lat, centerMap.lng)}
                              </div>
                              <div className={"box-camera-map"}>
                                <button className={"btn_camera"} onClick={OnCLickGetMapCamera}>
                                  <AiFillCamera/>
                                </button>
                                <Modal title="Configuration" visible={visibleModalCamera} okText={"Next"} onOk={handleOkRenderMap} onCancel={handleCancelRenderMap}>
                                  <Row>
                                    <Col span={12}>
                                      <label>Width: </label>
                                      <InputNumber
                                          min={1}
                                          max={1280}
                                          style={{
                                            width: "95%"
                                          }}
                                          addonAfter={"PX"} onChange={OnChangeWidthMapCrop} defaultValue={widthMapCrop} />
                                    </Col>
                                    <Col span={12}>
                                      <label>Height: </label>
                                      <InputNumber
                                          min={1}
                                          max={1280}
                                          style={{
                                            width: "95%"
                                          }}
                                          addonAfter={"PX"} onChange={OnChangeHeightMapCrop} defaultValue={heightMapCrop} />
                                    </Col>
                                  </Row>
                                    <Modal
                                        title="Crop map"
                                        centered
                                        visible={visibleModalCrop}
                                        onOk={OnCropImagesMap}
                                        okText={"Apply"}
                                        onCancel={() => setVisibleModalCrop(false)}
                                        width={1000}
                                    >
                                      <Select className={"select_option_map_crop"} defaultValue={optionCrop} onChange={handleChangeOptionCrop}>
                                        <Option value="crop_to_shape">Crop to shape</Option>
                                        <Option value="default">Default</Option>
                                      </Select>
                                      <Spin spinning={loadingCrop} tip="Loading...">
                                        <Cropper
                                            src={srcImgCrop}
                                            style={{ height: 600, width: "100%" }}
                                            aspectRatio={layer.width && layer.height ? layer.width / layer.height : 1}
                                            guides={true}
                                            scaleble={false}
                                            ref={cropperRef}
                                            ready={ChangeCropMap}
                                        />
                                      </Spin>
                                    </Modal>
                                </Modal>
                              </div>
                            </div>
                          </GoogleMap>
                        </LoadScript>
                      </div>
                </div>
              </div>
            </Modal>

            {/*{layer.typeMap ?*/}
            {/*    <div>*/}
            {/*      <Modal title="Back Yellow" visible={isModalBackyellow} onOk={()=>{*/}
            {/*        setIsModalBackyellow(false)*/}
            {/*      }*/}
            {/*      } onCancel={()=>{*/}
            {/*        setIsModalBackyellow(false)*/}
            {/*      }*/}
            {/*      }>*/}
            {/*      <img width={"100%"} height={"100%"} src={JsonToMap(StyleMap)}/>*/}

            {/*      </Modal>*/}
            {/*      <img onClick={()=>{*/}
            {/*        setIsModalBackyellow(true)*/}
            {/*      }*/}
            {/*      } style={{cursor: "pointer"}}  width={"60px"} height={"60px"} src={JsonToMap(StyleMap)}/>*/}

            {/*    </div>*/}
            {/*: null}*/}


            {layer.imageShape ?
              <Form.Item label={"Type shapes"} name={"type_shape_number"}>
                <Select defaultValue={"one"}>
                  <Option value="one">Only one</Option>
                  <Option value="multiple">Multiple</Option>
                </Select>
              </Form.Item>
            : null}

            {layer.type_shape_number == "multiple" ?
              <Form.Item label={"Maximum number of images"} name={"max_number_images_mulshape"}>
                <InputNumber min={1} defaultValue={10} />
              </Form.Item>
            : null}


            {layer.imageShape ?
                <Form.Item label="Shape" name={["shapes", 0, "file"]}>
                  <FileField
                      size={64}
                      loading="true"
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                        if (layer.personalized && layer.personalized.enable) {
                          let newLayers = layer.parent
                              ? layers.search(layer.parent).layers
                              : layers;
                          let layerDepend = newLayers.filter(
                              (item) => item.dependOnLayer === layer.id
                          );
                          if (layerDepend.length > 0) {
                            for (
                                let index = 0;
                                index < layerDepend.length;
                                index++
                            ) {
                              const element = layerDepend[index];
                              dispatch({
                                type: ARTWORK.SET_LAYER,
                                payload: {
                                  ...element,
                                  shapes: [{file: file}]
                                },
                              });
                            }
                          }
                        }
                      }}
                  />
                </Form.Item>
                : null }

            {layer.imageCutFace ?
                <Form.Item label="Image::" name={["values", 0, "file"]}>
                  <FileField size={64} accept=".jpg, .png, .jpeg" loading={true} />
                </Form.Item>
            : null}

            {layer.imageStarMap ?
            <Form.Item name="typeSkyStar" label="Choose a virtual sky type">
              <Select defaultValue={"polar"}>
                <Option value="polar">Default</Option>
                <Option value="stereo">Custom</Option>
              </Select>
            </Form.Item> : null }

            {OnPolar_typeSkyStar ?
                <a href="https://d111oohu250q1p.cloudfront.net/image_shape/1644994273-1337998-4256692373764.png" target='_blank'>
                  <img width="80" height="80" src="https://d111oohu250q1p.cloudfront.net/image_shape/1644994273-1337998-4256692373764.png"/>
                </a>
            : null}

            {OnStereo_typeSkyStar ?
                <Form.Item label="Shape" name={["shapes", 0, "file"]}>
                  <FileField
                      size={64}
                      loading="true"
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                        if (layer.personalized && layer.personalized.enable) {
                          let newLayers = layer.parent
                              ? layers.search(layer.parent).layers
                              : layers;
                          let layerDepend = newLayers.filter(
                              (item) => item.dependOnLayer === layer.id
                          );
                          if (layerDepend.length > 0) {
                            for (
                                let index = 0;
                                index < layerDepend.length;
                                index++
                            ) {
                              const element = layerDepend[index];
                              dispatch({
                                type: ARTWORK.SET_LAYER,
                                payload: {
                                  ...element,
                                  shapes: [{file: file}]
                                },
                              });
                            }
                          }
                        }
                      }}
                  />
                </Form.Item>
                : null }

            {layer.imageStarMap ? 
              <Form.Item name="scaleConstellations" label="Choose a scale constellations">
                <Select defaultValue={0.7}>
                  <Option value={0.7}>Lighter</Option>
                  <Option value={1.3}>Bolder</Option>
                </Select>
              </Form.Item>
            :null}

            {layer.imageStarMap ? 
              <Form.Item name="scaleGrid" label="Choose a scale grid">
                <Select defaultValue={0.5}>
                  <Option value={0.5}>Lighter</Option>
                  <Option value={1}>Bolder</Option>
                </Select>
              </Form.Item>
            :null}

            {layer.imageStarMap ? 
            <Form.Item name="scaleStars" label="Choose a scale stars">
              <Select defaultValue={1}>
                <Option value={1}>Lighter</Option>
                <Option value={2}>Bolder</Option>
              </Select>
            </Form.Item>
            : null}

            {layer.imageStarMap && layer.scaleStars ==1 ? 
            <>
              <img 
              style={{
                cursor: "pointer"
              }}
              width={60} 
              src="https://d111oohu250q1p.cloudfront.net/image_shape/1648799023-5741417-14807829143229.png" 
              onClick={()=>{setIsModalScaleStarsLighter(true)}}
              />

              <Modal title="Scale stars: Lighter" visible={isModalScaleStarsLighter} 
              onOk={()=>{
                setIsModalScaleStarsLighter(false)
              }}
              onCancel={()=>{
                setIsModalScaleStarsLighter(false)
              }}
              >
                <img width='100%' src="https://d111oohu250q1p.cloudfront.net/image_shape/1648799023-5741417-14807829143229.png"/>
              </Modal>
              </>
            :null}

            {layer.imageStarMap && layer.scaleStars ==2 ? 
            <>
              <img style={{
                cursor: "pointer"
              }}
              onClick={()=>{
                setIsModalScaleStarsBolder(true)
              }} width={60} src="https://d111oohu250q1p.cloudfront.net/image_shape/1648799116-8815868-14901135419266.png" />

              <Modal title="Scale stars: Bolder" visible={isModalScaleStarsBolder} 
              onOk={()=>{
                setIsModalScaleStarsBolder(false)
              }}
              onCancel={()=>{
                setIsModalScaleStarsBolder(false)
              }}
              >
                <img width="100%" src="https://d111oohu250q1p.cloudfront.net/image_shape/1648799116-8815868-14901135419266.png" />
              </Modal>

            </>
            :null}

            {layer.imageStarMap ?
                <Form.Item
                    name={"starcolor"}
                    label="Star Color:"
                >
                    <>
                    { display_color_starcolor ? <div style={ popover }>
                        <div style={ cover } onClick={()=>{
                            setdisplay_color_starcolor(false)
                        }
                        }/>
                        <ChromePicker
                            color={color_starcolor}
                            onChange={color => {
                                setcolor_starcolor(color.hex);
                                layer.starcolor = color.hex

                                dispatch({
                                  type: ARTWORK.SET_LAYER,
                                  payload: {
                                    ...layer,
                                    starcolor: layer.starcolor
                                  },
                                });
                            }}
                        />
                    </div> : null }

                    <div onClick={()=>{
                        setdisplay_color_starcolor(!display_color_starcolor)
                    }}
                         style={{
                             backgroundColor: color_starcolor,
                             height: "30px",
                             transition: "ease all 500ms",
                             border: "1px solid #000",
                             width: "30px",
                             borderRadius: "3px"
                         }}
                    />
                    </>
                </Form.Item>
                : null }

            {layer.imageStarMap ?
                <Form.Item
                    name={"transparentbackground"}
                    valuePropName="checked"
                    label="Background"
                >
                    <Checkbox>Transparent</Checkbox>

                </Form.Item>
            : null }

            {OnTransparentBackground ?

                <Form.List
                    name="backgroundcolors"
                    rules={[
                      {
                        validator: async (_, backgroundcolors) => {
                          if (!backgroundcolors || backgroundcolors.length < 1) {
                            return Promise.reject(new Error('At least 2 colors'));
                          }
                        },
                      },
                    ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                      <div>
                      <div className="Group_color_star_sky" style={{display: "flex", flexWrap: "wrap"}}>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? 'Background Colors' : ''}
                                required={false}
                                key={field.key}
                            >
                              <div className="Box_colors_list" style={{display: "block"}}>

                              <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: false,
                                      whitespace: true,
                                      message: "Please input passenger's name or delete this field.",
                                    },
                                  ]}
                                  noStyle
                              >
                                      <Input defaultValue={"#000001"} type={"color"} style={{ width: '30px',padding: 0 }} />
                                </Form.Item>
                              {fields.length > 1 ? (
                                  <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => remove(field.name)}
                                      style={{margin: "5px", position: "relative", bottom: "5px"}}
                                  />
                              ) : null}
                              </div>
                            </Form.Item>
                        ))}
                      </div>
                        <Form.Item>
                            <>
                          <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '50%', display: "block" }}
                              icon={<PlusOutlined />}
                              className="Button_add_color"
                          >
                            Add color
                          </Button>
                          <Form.ErrorList errors={errors} />
                            </>
                        </Form.Item>

                      </div>
                  )}
                </Form.List>
            : null}

            {!OnClipPartImage ?
            <Form.Item name={["personalized", "type"]} label="Using value of">
              <Radio.Group>
                <Radio
                  value="clipartCategory"
                  style={{ display: "block" }}
                  onChange={() => {
                    if (layer.personalized && layer.personalized.enable) {
                      let newLayers = layer.parent
                        ? layers.search(layer.parent).layers
                        : layers;
                      let layerDepend = newLayers.filter(
                        (item) => item.dependOnLayer === layer.id
                      );
                      if (layerDepend.length > 0) {
                        for (
                          let index = 0;
                          index < layerDepend.length;
                          index++
                        ) {
                          const element = layerDepend[index];
                          dispatch({
                            type: ARTWORK.SET_LAYER,
                            payload: {
                              ...element,
                              values: layer.personalized?.default?.file
                                ? [{ file: layer.personalized.default.file }]
                                : element.values,
                            },
                          });
                        }
                      }
                    }
                  }}
                >
                  Clipart category
                </Radio>
                <Radio
                  value="images"
                  style={{ display: "block" }}
                  onChange={() => {
                    if (layer.personalized && layer.personalized.enable) {
                      let newLayers = layer.parent
                        ? layers.search(layer.parent).layers
                        : layers;
                      let layerDepend = newLayers.filter(
                        (item) => item.dependOnLayer === layer.id
                      );
                      if (layerDepend.length > 0) {
                        for (
                          let index = 0;
                          index < layerDepend.length;
                          index++
                        ) {
                          const element = layerDepend[index];
                          dispatch({
                            type: ARTWORK.SET_LAYER,
                            payload: {
                              ...element,
                              values: layer.values[0]?.file
                                ? [{ file: layer.values[0].file }]
                                : element.value,
                            },
                          });
                        }
                      }
                    }
                  }}
                >
                  Upload Images
                </Radio>
              </Radio.Group>
            </Form.Item> : null }
            {get(layer, "personalized.type") == "images" && (
              <Form.Item name={["values", 0, "file"]}>
                <FileField
                  size={64}
                  loading="true"
                  accept=".jpg, .png, .jpeg"
                  onChange={(file) => {
                    if (layer.personalized && layer.personalized.enable) {
                      let newLayers = layer.parent
                        ? layers.search(layer.parent).layers
                        : layers;
                      let layerDepend = newLayers.filter(
                        (item) => item.dependOnLayer === layer.id
                      );
                      if (layerDepend.length > 0) {
                        for (
                          let index = 0;
                          index < layerDepend.length;
                          index++
                        ) {
                          const element = layerDepend[index];
                          dispatch({
                            type: ARTWORK.SET_LAYER,
                            payload: {
                              ...element,
                              values: [{ file: file }],
                            },
                          });
                        }
                      }
                    }
                  }}
                />
              </Form.Item>
              // <Form.Item label="Images" name="values">
              //   <ImagesField />
              // </Form.Item>
            )}
            {get(
              layer,
              "personalized.type",
              form.getFieldValue(["personalized", "type"])
            ) === "clipartCategory" && (
              <>
                <Form.Item
                  label="Choose category"
                  name={["personalized", "clipartCategory"]}
                  wrapperCol={{ span: 24 }}
                >
                  <ClipartCategorySelector />
                </Form.Item>
                <Form.Item
                  name={["personalized", "default"]}
                  label="Cliparts"
                  wrapperCol={{ style: { flex: "0 0 100%" } }}
                >
                  <Cliparts
                    categoryID={get(
                      layer,
                      "personalized.clipartCategory",
                      null
                    )}
                    onChange={(file) => {
                      if (layer.personalized && layer.personalized.enable) {
                        let newLayers = layer.parent
                          ? layers.search(layer.parent).layers
                          : layers;
                        let layerDepend = newLayers.filter(
                          (item) => item.dependOnLayer === layer.id
                        );
                        if (layerDepend.length > 0) {
                          for (
                            let index = 0;
                            index < layerDepend.length;
                            index++
                          ) {
                            const element = layerDepend[index];
                            dispatch({
                              type: ARTWORK.SET_LAYER,
                              payload: {
                                ...element,
                                values: [file],
                              },
                            });
                          }
                        }
                      }
                    }}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form>
    </Container>
  );
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(ImageForm);
