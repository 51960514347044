export const SHOPIFY_API = "8a7be891377ee5a50940961b7ecda3d2";
export const SHOPBASE_API = "71168728c79d70b595b18386315b8f7a";
export const CLOUDFRONT_URL = "https://d2i24l6ledg3db.cloudfront.net/";
export const APP_URL = "https://custom.oopprint.com";
export const API_URL = "https://api-custom.oopprint.com";
export const WS_URL = "wss://api-custom.oopprint.com";
export const DEFAULT_FONT = "fIbc7PWMie-regular";
export const ASSET_URL = 'https://d7re1xv4rs2gf.cloudfront.net/'
export const AWS_API = 'https://ok9grkv1sa.execute-api.ap-southeast-1.amazonaws.com';
export const URL_API_FILL_IMAGES_SHAPE = "https://d12jx00pqjbw1l.cloudfront.net/fill_images_shape"
export const URL_API_IMAGES_CARTOONIZE = "https://d39aqe1z2r2l90.cloudfront.net/cartoonize";
export const URL_API_IMAGES_CUT_FACE = "https://d39aqe1z2r2l90.cloudfront.net/cut-face";
export const URL_API_SEARCH_LYRIC = 'https://d1qw4sls0p3n8p.cloudfront.net/search';
export const URL_API_RENDER_SPIRAL_LYRICS = "https://d1rhcx0vt4s7t5.cloudfront.net/render-spiral-lyrics";
export const URL_API_UPLOAD_FILE = "https://m3uokktl3bctp5ms5rbpggifua0chhtj.lambda-url.ap-southeast-1.on.aws/upload-custom-font";
export const URL_API_RENDER_SPIRAL_CUSTOM_LYRICS = "https://d1rhcx0vt4s7t5.cloudfront.net/render-spiral-lyrics-url";
export const URL_GET_SIZE_ARTWORK = "https://d3nppxlsi6wa20.cloudfront.net/assets-app-custom/default-auto-size.json";
export const URL_API_MIDJOURNEY = "https://wkqsk2yn2dyzru6hn2xt2lgv7y0tfmpy.lambda-url.ap-southeast-1.on.aws"
export const URL_API_IMAGE_GENERATION_STREAM = "https://bizf3d2aoipvq4dxgd43d4msee0htjyp.lambda-url.ap-southeast-1.on.aws"
export const URL_API_KLING_CREATE_IMAGE = "https://po4zgkgoiaqxzvfaa57k6etbae0szcwt.lambda-url.ap-southeast-1.on.aws/create_image";
export const URL_API_KLING_CREATE_VIDEO = "https://po4zgkgoiaqxzvfaa57k6etbae0szcwt.lambda-url.ap-southeast-1.on.aws/create_video";
export const URL_API_KLING_CREATE_DESCRIBE = "https://7k4jmgh6umcroz3a2mf6p2u7xu0ezcwl.lambda-url.ap-southeast-1.on.aws/describe";
export const API_KEY_OPENAI = "sk-None-ln8EqcUu8kP40Ow9UNWgT3BlbkFJmxREviowzbmZoWiBLcUZ";
export const API_CHAT_CREATE_CONTENT_PRODUCT = "https://7k4jmgh6umcroz3a2mf6p2u7xu0ezcwl.lambda-url.ap-southeast-1.on.aws/ai-product";
export const AWS_API_UPLOAD_IMAGE = "https://otvrqmv113.execute-api.ap-southeast-1.amazonaws.com";
export const ASSET_URL_VIEW_IMAGE = "https://d1se9zaettx1vk.cloudfront.net/";
export const URL_API_AUTO_BANNER = "https://p2cwwi5cnsjglu56hcizomr3b40afubk.lambda-url.ap-southeast-1.on.aws/";
export const ASSET_API_AUTO_BANNER = "https://d2pl7y3si5bmtr.cloudfront.net/";
export const USER_ID_BLOCKED = "yFTsgxKBw9";
export const PASSWORD_UNLOCK_USER = '123123123';